.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Here are some HEX 
Banner: #FF8F0
Behind the print: #FFFAEB

Can you tone down the blue sky in the background of the ft myers picture? 

Accent colors:
#E2AA28
#9CB78F
#E4FFE1
#C7F1F9
#AED9E0 */
/* Color Pallete */
:root {
	--pallete1: #6a8d73;
	--pallete2: #f4fdd9;
	--pallete3: #e4ffe1;
	--pallete4: #ffe8c2;
	--pallete5: #f0a868;

	// Notes from Monica TODO: need to contact her for replacements
	/* --pallete1: #aed9e0;
	--pallete2: #e4ffe1;
	--pallete3: #9cb78f;
	--pallete4: #c7f1f9;
	--pallete5: #e2aa28; */

	--banner: #ff8f00;
	--behindPrint: #fffaeb;
}

//Override the color of invalid form-controls
.form-control.is-invalid {
	border-color: var(--pallete5) !important;
	background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f0a868%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e') !important;
}

.form-control.is-invalid:focus {
	box-shadow: 0 0 0 0.25rem rgba(240, 168, 104, 0.25) !important;
}
//Override the color and background images for invalid form-select
.form-select.is-invalid {
	border-color: var(--pallete5) !important;
	background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e'),
		url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f0a868%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e') !important;
}

//Override the border-color for invalid check and radio
.form-check-input.is-invalid {
	border-color: var(--pallete5) !important;
	background-color: var(--pallete5) !important;
}

/* NavigationBar */
#navigation-bar {
	background-color: var(--pallete2);
	/* background-color: var(--banner); */
}
.menu-link {
	color: var(--pallete5) !important;
}
/* remove dropdown caret */
.menu-link .nav-link::after {
	display: none !important;
}
#main-nav {
	margin-right: 4rem;
}
#main-nav a:hover {
	color: var(--pallete5);
}
#main-nav .dropdown:hover .dropdown-menu {
	display: block;
	margin-top: 0; /* remove the gap so it doesn't close */
}
@media screen and (max-width: 767px) {
	#navbar-logo {
		width: 200px;
	}
}

.home-card {
	height: 100% !important;
	box-shadow: 0px 0px 20px #c0bcbc;
	border: 2px solid #c0bcbc !important;
}
.home-card .card-body {
	background-color: var(--pallete4);
}
.home-card p {
	background-color: white;
	border: 1px solid var(--pallete3);
	padding: 0.5rem;
	border-radius: 1rem;
}
.home-card p:first-of-type {
	margin-top: 1rem;
}

/* Footer */
#main-footer {
	background-color: var(--pallete1);
	margin-top: auto;
	padding: 1rem;
	color: var(--pallete2);
}

/* Fullscreen layout with sticky footer*/
#root {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}
.page {
	margin-top: 103px;
	flex: 1;
	min-height: 500px;
}
@media screen and (max-width: 767px) {
	.page {
		margin-top: 50px;
	}
}

.highlight-text {
	color: var(--pallete5);
	font-weight: bold;
	font-style: italic;
}

.team-card h1 {
	color: var(--pallete5);
	font-weight: bold;
	font-family: Georgia, 'Times New Roman', Times, serif;
}

.practice-area-card {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}

.practice-area-card ul li {
	margin-bottom: 1rem;
}

.phone-link {
	color: var(--pallete1);
	text-decoration: none;
}
.phone-link:hover {
	color: var(--pallete5);
}

h1.soft-highlight {
	font-family: 'Arial, Helvetica, sans-serif';
	font-weight: bold;
	color: #fff;
	text-shadow: var(--pallete1) 0px 0px 7px;
}
.soft-highlight {
	background-color: var(--pallete4);
	border-radius: 0rem 0rem 2rem 2rem;
}
.team-heading {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
.team-card {
	box-shadow: 0px 0px 10px #ccc;
}
#home-page {
	position: relative;
	padding-top: 100px;
	padding-bottom: 100px;
}
@media screen and (max-width: 767px) {
	#home-page {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}
#home-bg-div {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	background-image: url('../public/images/fort_myers_bg.jpeg');
	background-position: center;
	background-size: cover;
	filter: saturate(4);
	opacity: 0.6;
}

/* Contact Styles */
#contact {
	.card {
		box-shadow: 0px 0px 20px #c0bcbc;
		border: 2px solid #c0bcbc !important;
	}

	#contact-envelope {
		font-size: 5rem;
		display: block;
		margin-left: auto;
		margin-right: auto;
		color: var(--pallete1);
	}
	#contact-warning {
		font-size: 5rem;
		display: block;
		margin-left: auto;
		margin-right: auto;
		color: var(--pallete5);
	}
	.form-text {
		display: inline-block;
		width: 100%;
		text-align: center;
	}
}

/* Potential New Client */
#potential-new-client {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;

	li {
		margin-bottom: 1rem;
	}

	#submission-success-check {
		font-size: 5rem;
		display: block;
		margin-left: auto;
		margin-right: auto;
		color: var(--pallete1);
	}
	#submission-failure-warning {
		font-size: 5rem;
		display: block;
		margin-left: auto;
		margin-right: auto;
		color: var(--pallete5);
	}

	.form-text {
		display: inline-block;
		width: 100%;
		text-align: center;
	}
}

/* Wills & Trusts Preliminary, Probate Preliminary */
#preliminary .card {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	background-color: white;

	input[type='radio'],
	input[type='checkbox'] {
		margin-left: 0.5rem;
	}
	.input-group .form-control.is-invalid {
		border-radius: 0 0.375rem 0.375rem 0;
	}
	.form-text {
		display: inline-block;
		width: 100%;
		text-align: center;
	}
}

.loading-overlay {
	background-color: #ccc;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0.8;

	#icon {
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 300px;
		color: var(--pallete1);
	}
	#icon {
		animation: spinner 3s infinite linear;
	}

	@keyframes spinner {
		0% {
			transform: translate(-50%, -50%) rotate3d(0, 1, 0, 0deg);
		}
		50% {
			transform: translate(-50%, -50%) rotate3d(0, 1, 0, 180deg);
		}
		100% {
			transform: translate(-50%, -50%) rotate3d(0, 1, 0, 360deg);
		}
	}
}

.shadow-card {
	box-shadow: 0px 0px 10px #ccc;
}

//TimedAlertModal
.timed-alert-modal {
	.modal-dialog {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -60%) !important;
	}

	.timed-alert-modal-icon.success {
		color: var(--pallete1);
		font-size: 5rem;
	}
	.timed-alert-modal-icon.failure {
		color: var(--pallete5);
		font-size: 5rem;
	}
}

/* Bootstrap Overrides */
.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
	color: white;
}

/* Admin Page */
#admin-pnc {
	.card {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}
	.contact-card {
		width: fit-content;

		.card-body {
			width: fit-content;
		}
	}
}
