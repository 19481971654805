.customStatesDropdown {
	input {
		padding-right: 3rem;
		background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e');
		background-repeat: no-repeat;
		background-position: calc(100% - 12px);
		background-size: 12px;
	}
	input.is-invalid {
		background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e'),
			url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f0a868%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e') !important;
		padding-right: 4.125rem;
		background-position: right 0.75rem center, center right 2.25rem;
		background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	}
	.dropdown-toggle::after {
		display: none;
		position: absolute;
		right: 1rem;
		top: 50%;
	}
	.dropdown-menu {
		max-height: 30vh;
		overflow-y: auto;
	}
}
